import Plyr from 'plyr'

const init = () => {

  // const player = new Plyr('.js-player', {})
  const mytap = (window.ontouchstart === null) ? 'touchstart' : 'click'

  const player = Array.from(document.querySelectorAll('.js-player'))
      .map((p) => new Plyr(p))

  /* if enabled autoplay on modal open
      const openModal = document.querySelectorAll('.learn-item')
      for (let i = 0; i < openModal.length; i++) {
          openModal[i].addEventListener('click', () => {
            // player[i].play()
            console.log('player' + i)
          })
      }
    */

  const closeModalOverlay = document.querySelectorAll('.modal__overlay_in')
  for (let i = 0; i < closeModalOverlay.length; i++) {

    closeModalOverlay[i].addEventListener(mytap, () => {
      player[i].pause()
      console.log('pause' + i)
    })

  }
  const closeModal = document.querySelectorAll('.js-video-stop')
  for (let i = 0; i < closeModal.length; i++) {

    closeModal[i].addEventListener(mytap, () => {
      player[i].pause()
      /*closeModalOverlay.click()*/
      console.log('pause' + i)
    })

  }

  /* if enabled autoplay on modal open
    document.addEventListener('closeModal', function() { // (1)
      for (let i = 0; i < closeModal.length; i++) {
        player[i].pause()
      }
    })
  */
}

export default {
  init
}
