const init = () => {

  function copyToClipboard(str) {
    var area = document.createElement('textarea')

    document.body.appendChild(area)

    area.value = str.replace(/(<([^>]+)>)/ig,'')
                    .replace(/&amp;/g, '&')
                    .replace(/&gt;/g, '>')
                    .replace(/&lt;/g, '<')

    area.select()
    document.execCommand('copy')
    document.body.removeChild(area)
  }


  let boxClon = document.querySelectorAll('.js-code-box')
  boxClon.forEach(function(item) {
    let test = item.querySelector('.js-copy-code')
    let btnClon = item.querySelector('.js-copy-code-btn')

    btnClon.addEventListener('click', (e)=>{
      e.preventDefault()
      copyToClipboard(test.innerHTML)
    })
  })

}

export default {
  init
}