const init = () => {

  const anchors = [].slice.call(document.querySelectorAll('a[href*="#"]:not(.js-page-link)')),
      animationTime = 400,
      framesCount = 20,
      animationOffset = 20

  anchors.forEach(function(item) {
    item.addEventListener('click', function(e) {
      e.preventDefault()

      if (document.querySelector(item.getAttribute('href')) !== null ) {

        let coordY = document.querySelector(item.getAttribute('href')).getBoundingClientRect().top + window.pageYOffset  - animationOffset

        let scroller = setInterval(function() {
          let scrollBy = coordY / framesCount

          if(scrollBy > window.pageYOffset - coordY && window.innerHeight + window.pageYOffset < document.body.offsetHeight - animationOffset) {
            window.scrollBy(0, scrollBy)
          } else {
            window.scrollTo(0, coordY)
            clearInterval(scroller)
          }
        }, animationTime / framesCount)
      }
    })
  })

}


export default {
  init
}
