import Components from './components'
import * as Sentry from '@sentry/browser'

import '../scss/main.scss'

Sentry.init({
  dsn: 'https://749376843f8f4879a4b24f7611912c8b@sentry.yournext.agency/36'
})

if (Components.length) {
  Components.forEach((component) => component.init())
}
