const init = () => {
  const acc = document.getElementsByClassName('js-accordion')
  let i

  for (i = 0; i < acc.length; i += 1) {
    const currBtn = acc[i]
    const currPanel = currBtn.nextElementSibling
    if (currPanel.classList.contains('is-open')) {
      currPanel.style.maxHeight = `${currPanel.scrollHeight + 2}px`
    }

    acc[i].addEventListener('click', (e) => {
      const btn = e.currentTarget

      const openPanel = (panel) => {
        if (panel.style.maxHeight || panel.classList.contains('is-open')) {
          panel.style.maxHeight = null
          panel.classList.remove('is-open')
        } else {
          panel.style.maxHeight = `${panel.scrollHeight + 2}px`
          panel.classList.add('is-open')
        }
      }

      const closePanel = (panelBtn) => {
        if (panelBtn.classList.contains('is-active')) {
          panelBtn.style.maxHeight = null
          panelBtn.classList.remove('is-open')
          panelBtn.classList.remove('is-active')
        }
      }

      const accordionMqValue = btn.dataset.accordionMq
      const { closeOtherPanels } = btn.dataset

      if (typeof accordionMqValue !== 'undefined') {
        const mq = window.matchMedia(`${accordionMqValue}`)
        if (!mq.matches) {
          return false
        }
      }

      if (typeof closeOtherPanels !== 'undefined') {
        if (closeOtherPanels === 'true') {
          const siblingPanels = btn.closest('.js-accordion-container').querySelectorAll('.js-accordion')
          for (const sibling of siblingPanels) {
            if (sibling !== btn) {
              closePanel(sibling)
            }
          }
        }
      }

      btn.classList.toggle('is-active')
      const panel = btn.nextElementSibling
      openPanel(panel)
    })
  }
}

export default {
  init
}
