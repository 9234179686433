const init = () => {

  // const btnFilter = document.querySelector('.js-filter-btn')
  const boxFilter = document.querySelector('.js-filter-box')
  if (boxFilter) {
    boxFilter.addEventListener('click', () => {
      boxFilter.classList.add('is-active')
    })
  }

  const btnFilterRes = document.querySelectorAll('.js-open-filter')
  btnFilterRes.forEach(link => {
        link.addEventListener('click', () => {
          boxFilter.classList.remove('filter-active')
        })
      })

}


export default {
  init
}
