import Swiper from 'swiper'
// import 'swiper/css'

const init = () => {
  new Swiper('.swiper-1', {
    // Optional parameters
    cssMode: true,
    mousewheel: true,
    keyboard: true,
    slidesPerView: 'auto',
    spaceBetween: 24
  })

  new Swiper('.swiper-2', {
    // Optional parameters
    cssMode: true,
    mousewheel: true,
    keyboard: true,
    slidesPerView: 'auto',
    spaceBetween: 10
  })
}

export default {
  init
}
