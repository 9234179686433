import SlimSelect from 'slim-select'

const init = () => {

  const forms = document.querySelectorAll('.js-form')
  const selects = document.querySelectorAll('.js-slim-select')
  let selectsArr = []

  if (!forms.length) {
    return false
  }

  const initSelects = () => {
    selects.forEach((item) => {
      const select = new SlimSelect({
        select: item,
        settings: {
          showSearch: false,
          placeholderText: 'Select'
        }
      })
      selectsArr.push(select)
    })
  }

  initSelects()

  forms.forEach((form) => {
    form.addEventListener('onAfterFormieSubmit', (e) => {
      selectsArr.forEach((item) => {
        item.destroy()
      })
      e.target.reset()
      initSelects()
      const submit = e.target.querySelector('button[data-submit-action="submit"]')
      if (submit !== null) {
        submit.removeAttribute('disabled')
      }
    })
  })
}

export default {
  init
}
