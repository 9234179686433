const init = () => {

  const btnNav = document.querySelector('.js-btn-nav')
  const boxNav = document.querySelector('.main-nav')
  const body = document.querySelector('body')
  if (btnNav) {
    btnNav.addEventListener('click', () => {
      btnNav.classList.toggle('active')
      boxNav.classList.toggle('active')
      body.classList.toggle('main-nav-open')
      window.scrollTo(0, 0)
    })
  }
  // const boxNavLink = document.querySelectorAll('.js-nav-link')
  document.querySelectorAll('.js-nav-link')
      .forEach(link => {
        if (link.classList.contains('has-descendants')) {
          link.addEventListener('click', () => {
            link.classList.toggle('active')
            event.preventDefault()
          })
        }
      })

  document.querySelectorAll('.main-nav-lv2')
      .forEach(link => {
        link.previousElementSibling.classList.add('main-nav-lv-wr')
        link.previousElementSibling.addEventListener('click', (event) => {
          event.preventDefault()
        })
      })

}

export default {
  init
}
