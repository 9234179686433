import Filter from './filter'
import Modal from './modal'
import Nav from './nav'
import Swiper from './swiper'
import Anchor from './anchor'
import Video from './video'
import Tabs from './tabs'
import Code from './copyCode'
import LoadMoreEntries from './loadMoreEntries'
import Accordion from './accordion'
import Forms from './forms'

export default [
  Filter,
  Forms,
  Modal,
  Nav,
  Swiper,
  Anchor,
  Video,
  Tabs,
  Code,
  LoadMoreEntries,
  Accordion
]
