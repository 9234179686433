import MicroModal from 'micromodal'

const body = document.querySelector('body')
const init = () => {
  const setURLSearchParam = (key, value) => {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState({ path: url.href }, '', url.href)
  }

  const modals = document.querySelectorAll('.modal')
  if (modals) {
    MicroModal.init({
      onShow: modal => {
        console.info(`${modal.id} is shown`)
        body.classList.add('open-modal')
        setURLSearchParam('open-modal', modal.id)
      }, // [1]
      onClose: modal => {
        body.classList.remove('open-modal')
        body.classList.remove('open-modal')
        console.info(`${modal.id} is hidden`)

        /* if enabled autoplay on modal open
          let event = new Event('closeModal', {bubbles: true}) // (2)
          document.dispatchEvent(event)
        */

      }, // [2]
      openTrigger: 'data-micromodal-open', // [3]
      closeTrigger: 'data-micromodal-close', // [4]
      openClass: 'is-open', // [5]
      disableScroll: false, // [6]
      disableFocus: false, // [7]
      awaitOpenAnimation: false, // [8]
      awaitCloseAnimation: true, // [9]
      debugMode: true // [10]
    })

    const url = new URL(window.location.href)
    const openedModalId = url.searchParams.get('open-modal')
    if (!openedModalId) {
      return false
    }
    modals.forEach(el => {
      // console.log('open ', el.id)
      if (openedModalId === el.id) {
        // eslint-disable-next-line
        // console.log('open form', el.id)
        MicroModal.show(el.id)
      }
    })
  }
}

export default {
  init
}
